import {
  isPlainObject, isFinite, pickBy, toLower,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const BasketballPerPeriod = (perPeriod, { side }) => pickBy(
  {
    FIRST_PERIOD: perPeriod?.FIRST_PERIOD?.[side],
    SECOND_PERIOD: perPeriod?.SECOND_PERIOD?.[side],
    THIRD_PERIOD: perPeriod?.THIRD_PERIOD?.[side],
    FOURTH_PERIOD: perPeriod?.FOURTH_PERIOD?.[side],
    OVERTIME_1: perPeriod?.OVERTIME_1?.[side],
    OVERTIME_2: perPeriod?.OVERTIME_2?.[side],
    OVERTIME_3: perPeriod?.OVERTIME_3?.[side],
    OVERTIME_4: perPeriod?.OVERTIME_4?.[side],
    OVERTIME_5: perPeriod?.OVERTIME_5?.[side],
    OVERTIME_6: perPeriod?.OVERTIME_6?.[side],
    OVERTIME_7: perPeriod?.OVERTIME_7?.[side],
    OVERTIME_8: perPeriod?.OVERTIME_8?.[side],
    OVERTIME_9: perPeriod?.OVERTIME_9?.[side],
    OVERTIME_10: perPeriod?.OVERTIME_10?.[side],
  },
  isFinite,
);

const BasketballCompetitor = (event, { side, details }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  return {
    ...competitor,
    score: details?.[`${toLower(side)}Score`] || 0,
    scorePerPeriod: BasketballPerPeriod(details?.scorePerPeriod, { side }),
    onePointersPerPeriod: BasketballPerPeriod(details?.onePointersPerPeriod, { side }),
    twoPointersPerPeriod: BasketballPerPeriod(details?.twoPointersPerPeriod, { side }),
    threePointersPerPeriod: BasketballPerPeriod(details?.threePointersPerPeriod, { side }),
    foulsPerPeriod: BasketballPerPeriod(details?.foulsPerPeriod, { side }),
    fieldGoalPerPeriod: BasketballPerPeriod(details?.fieldGoalPerPeriod, { side }),
    fieldGoalPercentagePerPeriod: BasketballPerPeriod(details?.fieldGoalPercentagePerPeriod, { side }),
    fieldGoalPercentageInMatch: details?.fieldGoalPercentageInMatch?.[side],
    twoPointAttemptsPerPeriod: BasketballPerPeriod(details?.twoPointAttemptsPerPeriod, { side }),
    threePointAttemptsPerPeriod: BasketballPerPeriod(details?.threePointAttemptsPerPeriod, { side }),
    reboundsPerPeriod: BasketballPerPeriod(details?.reboundsPerPeriod, { side }),
    assistsPerPeriod: BasketballPerPeriod(details?.assistsPerPeriod, { side }),
    possessionsCountPerPeriod: BasketballPerPeriod(details?.possessionsCountPerPeriod, { side }),
    stealsPerPeriod: BasketballPerPeriod(details?.stealsPerPeriod, { side }),
    offensiveReboundsPerPeriod: BasketballPerPeriod(details?.offensiveReboundsPerPeriod, { side }),
    turnoversPerPeriod: BasketballPerPeriod(details?.turnoversPerPeriod, { side }),
  };
};

export default (data, selectedFeed) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data, selectedFeed);
  const details = eventHelpers.findEventDetails(data, selectedFeed);

  return {
    ...event,
    period: details?.period || 'N_A',
    periods: details?.periods || 0,
    secondsLeftInPeriod: details?.secondsLeftInPeriod || 0,
    periodLengthInMinutes: details?.periodLengthInMinutes || 0,
    overtimeLengthInMinutes: details?.otLengthInMinutes || 0,
    isOvertimePossible: details?.otPossible || false,
    foulThresholdForBonus: details?.foulThresholdForBonus || 0,
    isClockRunning: details?.clockRunning || false,
    teamInPossession: details?.teamInPossession || 'NEUTRAL',
    competitionType: details?.competitionType || '',
    home: BasketballCompetitor(event, { side: 'HOME', details }),
    away: BasketballCompetitor(event, { side: 'AWAY', details }),
    homeLineup: details?.homeLineup || [],
    awayLineup: details?.awayLineup || [],
    inBreak: details?.eventInBreak,
    inPrematch: details?.eventPreMatch,
    actions: details?.actions ?? [],
    riskFlags: details?.riskFlags || [],
  };
};
